import { capitalizeFirstLetter } from 'app/utils';

export const formatUsersResourceName = resourceName => `${capitalizeFirstLetter(resourceName)} Management`;

export const formatUsersPermissionsName = pName => pName.split('_').map(capitalizeFirstLetter).join(' ');

export const formatUsersRolesName = rName => rName.split('-').join(' ');

export const areStringArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const sortedArr1 = [...arr1].map(str => str.toLowerCase()).sort();
    const sortedArr2 = [...arr2].map(str => str.toLowerCase()).sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}