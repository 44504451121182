import React, { useState } from 'react';
import { Modal, Button, ButtonIcon } from 'app/components';
import { Download, Copy, ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import { copyToClipboard } from 'app/utils';
import Editor from '@monaco-editor/react';
import './index.scss';

const AuditInfoModal = (props) => {
  const { title, auditInfo, onClose } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([auditInfo[currentIndex].resource], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "auditLog.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % auditInfo.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + auditInfo.length) % auditInfo.length);
  };

  function handleEditorBeforeMount(monaco) {
    // ensure that the 'vs-dark' theme is applied before the editor mounts (dark mode is buggy sometimes so we manually set it here)
    setTimeout(() => {
      monaco.editor.setTheme('vs-dark');
    }, 1);
  }

  return (
    <Modal
      className="audit-info-modal"
      hideButtons={true}
      onClose={() => { onClose(null); }}
    >
      <div className="audit-info-modal-header">
        <div>{title}</div>
        {auditInfo && auditInfo.length > 0 && (
          <div className="header-icons">
            <ButtonIcon
              icon={<Copy />}
              onClick={() => copyToClipboard(auditInfo[currentIndex].resource)}
            />
            <ButtonIcon
              icon={<Download />}
              onClick={handleDownload}
            />
          </div>
        )}
      </div>
      {auditInfo && auditInfo.length > 0 ? (
        <Editor
          className="audit-info-modal-data"
          options={{
            overviewRulerLanes: 0,
            minimap: { enabled: false },
            wordWrap: 'on',
            theme: 'vs-dark',
            readOnly: true,
          }}
          height="400px"
          defaultLanguage="json"
          value={auditInfo[currentIndex].resource}
          beforeMount={handleEditorBeforeMount}
        />
      ) : (
        <div>
          No audit information available.
        </div>
      )}
      <div className="audit-info-modal-footer">
        <div className="message-pagination-container">
          {auditInfo.length > 1 && (
            <>
              <ButtonIcon
                icon={<ArrowLeft />}
                onClick={handlePrev}
              />
              <span>{`Message ${currentIndex + 1} of ${auditInfo.length}`}</span>
              <ButtonIcon
                icon={<ArrowRight />}
                onClick={handleNext}
              />
            </>
          )}
        </div>
        <Button
          variant="primary"
          size="small"
          label="Close"
          onClick={() => { onClose(null); }}
        />
      </div>
    </Modal>
  );
};

export default AuditInfoModal;
