import { createAction } from 'redux-actions';

const prefix = "NOTIFICATION";

export const getWebhooks = createAction(`${prefix}/GET_WEBHOOKS`);
export const getWebhooksSuccess = createAction(`${prefix}/GET_WEBHOOKS_SUCCESS`);
export const getWebhooksFailure = createAction(`${prefix}/GET_WEBHOOKS_FAILURE`);

export const getWebhookDetails = createAction(`${prefix}/GET_WEBHOOK_DETAILS`);
export const getWebhookDetailsSuccess = createAction(`${prefix}/GET_WEBHOOK_DETAILS_SUCCESS`);
export const getWebhookDetailsFailure = createAction(`${prefix}/GET_WEBHOOK_DETAILS_FAILURE`);

export const subscribeToWebhook = createAction(`${prefix}/SUBSCRIBE_TO_WEBHOOK`);
export const subscribeToWebhookSuccess = createAction(`${prefix}/SUBSCRIBE_TO_WEBHOOK_SUCCESS`);
export const subscribeToWebhookFailure = createAction(`${prefix}/SUBSCRIBE_TO_WEBHOOK_FAILURE`);

export const updateWebhook = createAction(`${prefix}/UPDATE_WEBHOOK`);
export const updateWebhookSuccess = createAction(`${prefix}/UPDATE_WEBHOOK_SUCCESS`);
export const updateWebhookFailure = createAction(`${prefix}/UPDATE_WEBHOOK_FAILURE`);

export const deleteWebhookSubscription = createAction(`${prefix}/DELETE_WEBHOOK_SUBSCRIPTION`);
export const deleteWebhookSubscriptionSuccess = createAction(`${prefix}/DELETE_WEBHOOK_SUBSCRIPTION_SUCCESS`);
export const deleteWebhookSubscriptionFailure = createAction(`${prefix}/DELETE_WEBHOOK_SUBSCRIPTION_FAILURE`);

export const testWebhookSubscription = createAction(`${prefix}/TEST_WEBHOOK_SUBSCRIPTION`);
export const testWebhookSubscriptionSuccess = createAction(`${prefix}/TEST_WEBHOOK_SUBSCRIPTION_SUCCESS`);
export const testWebhookSubscriptionFailure = createAction(`${prefix}/TEST_WEBHOOK_SUBSCRIPTION_FAILURE`);

export const getMessagesAuditInfo = createAction(`${prefix}/GET_MESSAGES_AUDIT_INFO`);
export const getMessagesAuditInfoSuccess = createAction(`${prefix}/GET_MESSAGES_AUDIT_INFO_SUCCESS`);
export const getMessagesAuditInfoFailure = createAction(`${prefix}/GET_MESSAGES_AUDIT_INFO_FAILURE`);

export const getWebhookAuditInfo = createAction(`${prefix}/GET_WEBHOOK_AUDIT_INFO`);
export const getWebhookAuditInfoSuccess = createAction(`${prefix}/GET_WEBHOOK_AUDIT_INFO_SUCCESS`);
export const getWebhookAuditInfoFailure = createAction(`${prefix}/GET_WEBHOOK_AUDIT_INFO_FAILURE`);

export const getNotificationAuditLogs = createAction(`${prefix}/GET_NOTIFICATION_AUDIT_LOGS`);
export const getNotificationAuditLogsSuccess = createAction(`${prefix}/GET_NOTIFICATION_AUDIT_LOGS_SUCCESS`);
export const getNotificationAuditLogsFailure = createAction(`${prefix}/GET_NOTIFICATION_AUDIT_LOGS_FAILURE`);

export const searchWebhooks = createAction(`${prefix}/SEARCH_WEBHOOKS`);
export const searchWebhooksSuccess = createAction(`${prefix}/SEARCH_WEBHOOKS_SUCCESS`);
export const searchWebhooksFailure = createAction(`${prefix}/SEARCH_WEBHOOKS_FAILURE`);

export const clearErrors = createAction(`${prefix}/CLEAR_ERRORS`);
export const reset = createAction(`${prefix}/RESET`);