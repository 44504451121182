import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Col, Row } from 'react-bootstrap';
import { updateShippingAddress } from 'app/store/actions/order';
import { PencilSquare } from 'react-bootstrap-icons';
import { Button, ButtonIcon, Input, Card } from 'app/components'
import './index.scss';
import { emailRegex } from 'app/utils';

const CustomerDetails = props => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const { orderId, shippingAddress } = props;

  useEffect(() => {
    setEditMode(false);
  }, [shippingAddress]);

  return (
    <>
      <Card className="customer-details-card">
        <Card.Header>
          Customer Details
          {!editMode && (
            <ButtonIcon
              icon={<PencilSquare />}
              onClick={() => setEditMode(true)}
            />
          )}
        </Card.Header>
        <Card.Body>
          {!editMode && (
            <>
              <div className="data-value">{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</div>
              {shippingAddress.phone && <div className="data-value">{shippingAddress.phone}</div>}
              {shippingAddress.email && <div className="data-value">{shippingAddress.email}</div>}
            </>
          )}
          {editMode && (
            <Formik
              initialValues={{
                firstName: shippingAddress.firstName || '',
                lastName: shippingAddress.lastName || '', 
                address1: shippingAddress.line1 || '',
                address2: shippingAddress.line2 || '',
                city: shippingAddress.city || '',
                state: shippingAddress.state || '',
                zipPostalCode: shippingAddress.zip || '',
                country: shippingAddress.countryCode || 'US',
                phone: shippingAddress.phone || '',
                email: shippingAddress.email || '',
              }}
              validationSchema={() =>
                object().shape({
                  firstName: string()
                    .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid First Name') // name
                    .matches(/[\x20-\x7F]+/, 'Enter a valid First Name') // onlyEnglish
                    .required('Enter a valid First Name'), // required
                  lastName: string()
                    .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid Last Name') // name
                    .matches(/[\x20-\x7F]+/, 'Enter a valid Last Name') // onlyEnglish
                    .required('Enter a valid Last Name'), // required
                  phone: string()
                    .matches(
                      /^[+\-\s./0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                      'Enter a valid Phone Number'
                    )
                    .matches(/^.{0,15}$/, 'Please limit to 15 characters')
                    .required('Enter a valid Phone Number'),
                  email: string()
                    .matches(
                      emailRegex,
                      'Enter a valid Email'
                    )
                    .required('Enter a valid Email'),
                })
              }
              onSubmit={async (values) => {
                const newShippingAddress =  {
                  "shippingAddress": {
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "line1": values.address1,
                    "line2": values.address2,
                    "city": values.city,
                    "state": values.state,
                    "zip": values.zipPostalCode,
                    "countryCode": values.country,
                    "phone": values.phone,
                    "email": values.email,
                  }
                };

                dispatch(updateShippingAddress({orderId, data: newShippingAddress}));
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                submitCount,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col xl={12} xxl={6}>
                      <Input
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        placeholder="First Name"
                        errorMessage={submitCount > 0 && errors.firstName}
                      />
                    </Col>
                    <Col xl={12} xxl={6}>
                      <Input
                        label="Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        placeholder="Last Name"
                        errorMessage={submitCount > 0 && errors.lastName}
                      />
                    </Col>
                  </Row>
                  <Input
                    label="Phone Number"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    errorMessage={submitCount > 0 && errors.phone}
                  />
                  <Input
                    label="Email Address"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Email Address"
                    errorMessage={submitCount > 0 && errors.email}
                  />
                  <div className="action-buttons">
                    <Button
                      variant="secondary"
                      size='small'
                      label='Cancel'
                      onClick={() => setEditMode(false)}
                    />
                    <Button
                      variant="primary"
                      size='small'
                      label='Update'
                      onClick={() => (isSubmitting ? null : handleSubmit())}
                    />
                  </div>
                </form>
              )}
            </Formik>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default CustomerDetails;