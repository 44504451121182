import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class RoutingServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.RoutingMS.Url
    });

    SetupInterceptors(this.api);
  }

  createRoutingBlock = async (data) => {
    try {
      const response = await this.api.post(`/v1/blocks`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error creating block');
    }
  };

  bulkCreateRoutingBlocks = async (data) => {
    try {
      const response = await this.api.post(`/v1/blocks/bulk`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error creating blocks');
    }
  };

  updateRoutingBlock = async (data) => {
    try {
      const response = await this.api.put(`/v1/blocks`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating block');
    }
  };

  bulkUpdateRoutingBlocks = async (data) => {
    try {
      const response = await this.api.put(`/v1/blocks/bulk`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating blocks');
    }
  };

  deleteRoutingBlock = async (blockId) => {
    try {
      const response = await this.api.delete(`/v1/blocks/${blockId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting block');
    }
  };

  bulkDeleteRoutingBlocks = async (data) => {
    try {
      const response = await this.api.delete(`/v1/blocks/bulk`, { data: data });
      return response.data;
    } catch (error) {
      throw new Error('Error deleting blocks');
    }
  };

  createStrategy = async (routingStrategy) => {
    try {
      const response = await this.api.post(`/v1/routing-strategies`, routingStrategy);
      return response.data;
    } catch (error) {
      throw new Error('Error creating strategy');
    }
  };

  updateStrategy = async (routingStrategy) => {
    try {
      const response = await this.api.put(`/v1/routing-strategies`, routingStrategy);
      return response.data;
    } catch (error) {
      throw new Error('Error updating strategy');
    }
  };

  // get all strategies for a merchant
  getStrategiesForMerchant = async (merchantId) => {
    try {
      const response = await this.api.get(`/v1/routing-strategies/merchant/${merchantId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error getting strategies');
    }
  };

  // get details for a specific strategy
  getStrategy = async (strategyId) => {
    try {
      const response = await this.api.get(`/v1/routing-strategies/${strategyId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error getting strategy');
    }
  };

  deleteStrategy = async (strategyId) => {
    try {
      const response = await this.api.delete(`/v1/routing-strategies/${strategyId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting strategy');
    }
  };

  activateStrategy = async (strategyId) => {
    try {
      const response = await this.api.post(`/v1/activate/${strategyId}`, {routingStrategyId : strategyId});
      return response.data;
    } catch (error) {
      throw new Error('Error activating strategy');
    }
  };

  getDataSets = async (strategyId) => {
    try {
      const response = await this.api.get(`/v1/routing-strategies/${strategyId}/data-sets`);
      return response.data;
    } catch (error) {
      throw new Error('Error getting data sets');
    }
  }
}

export default new RoutingServices();