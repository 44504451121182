import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { ButtonIcon, Card, Button } from "app/components";
import { ArrowLeft, Upload } from "react-bootstrap-icons";
import { parseCSV } from "app/utils";

const csvExampleUrl =
  "https://printmeeappassets.blob.core.windows.net/ordermesh/Data%20Set%20template.csv";
const csvExampleName = "Data Set Template.csv";
const maxFileSize = 10;

const UploadDataSetCSV = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const items = parseCSV(text);
        console.log("items", items);
      }
      reader.readAsText(file);
    }
  }

  return (
    <div className="upload-dataset-csv">
      <Card>
        <div className="upload-dataset-csv-header">
          <div className="upload-dataset-csv-title">
            <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate(-1)} />
            Upload Data Set
          </div>
          <Button
            variant="primary"
            size="medium"
            label="Download Template CSV"
            onClick={() => {
              const link = document.createElement("a");
              link.href = csvExampleUrl;
              link.download = csvExampleName;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          />
        </div>
        <div>
        </div>
        <div className="dataset-content-holder d-flex justify-content-center align-items-center">
          <div
            className="upload-container"
            onClick={handleDivClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".csv"
              onChange={handleFileChange}
            />
            <Upload className="upload-icon"></Upload>
            <div className="drag-text">Drag and Drop your File to Upload</div>
            <div className="gray-text">File supported:CSV</div>
            <Button label="Choose a file" onClick={() => 0}></Button>
            <div className="gray-text">Maximum file size {maxFileSize} MB</div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default UploadDataSetCSV;
