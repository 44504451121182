import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { getRoutingStrategyDataSets } from "app/store/actions/routing";
import { getDataSetsLoadingSelector, getDataSetsSelector } from "app/store/selectors/routing";
import { LoadingAnimation, Table, TableHeader, ButtonIcon, Card, Button } from "app/components";
import { ThreeDotsVertical } from "react-bootstrap-icons";

import "./index.scss";

const DataSets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { strategyId, merchantId } = useParams();

  const isLoading = useSelector(getDataSetsLoadingSelector);
  const dataSets = useSelector(getDataSetsSelector);

  useEffect(() => {
    dispatch(getRoutingStrategyDataSets({ strategyId }));
  }, [strategyId]);

  const handleUploadDataSetCSVClick = () => {
    console.log("Upload Data Set CSV");
    navigate(`/admin/routing/${merchantId}/${strategyId}/upload-csv`);
  };

  const actionsMenu = (dataSet) => [
    {
      label: "View",
      onClick: () => console.log("View", dataSet),
    },
    {
      label: "Edit",
      onClick: () => console.log("Edit", dataSet),
    },
    {
      label: "Download",
      onClick: () => console.log("Download", dataSet),
    },
    {
      label: "Delete",
      onClick: () => console.log("Delete", dataSet),
    },
  ];

  return <div className="strategy-data-sets">
    {isLoading && <LoadingAnimation />}
    <Card>
      <div className="data-sets-header d-flex">
        <h5>Uploaded Data Sets</h5>
        <div className="d-flex">
          <Button
            className="mx-2"
            variant="secondary"
            size="small"
            onClick={handleUploadDataSetCSVClick}
            label={"Upload Data Set"} />
          <Button
            variant="secondary"
            size="small"
            label={"Download Template"} />
        </div>
      </div>
      <Table
        className="mt-4"
        size="medium">
        <TableHeader
          options={[
            { id: "name", label: "Name", orderable: true },
            { id: "variables: ", label: "Variables", orderable: true },
            { id: "actions", label: "Actions", align: "center" }
          ]}
        />
        <tbody className="table-body">
          {dataSets && dataSets.map((dataSet) => (
            <tr className="data-set-row" key={strategyId}>
              <td>{dataSet?.name}

              </td>
              <td>{dataSet?.variables?.map(v => v.name).join(', ')}</td>
              <td className="data-set-actions-menu">
                <ButtonIcon
                  icon={<ThreeDotsVertical />}
                  options={actionsMenu(null)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  </div>;
};

export default DataSets;