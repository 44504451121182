import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'app/components';
import { resubmitShipment } from 'app/store/actions/shipment';
import './index.scss';

const ResubmitShipmentModal = (props) => {
  const { orderId, shipmentId, showResubmitShipmentModal } = props;
  const dispatch = useDispatch();

  return (
    <Modal
      className="resubmit-shipment-modal"
      title={`Resubmit Shipment`}
      hideButtons={true}
      onClose={() => { showResubmitShipmentModal(null); }}
    >
      <div className="body-message">
        Are you sure you want to resubmit this shipment?
      </div>
      <div className="action-buttons">
        <Button
          variant="secondary"
          size="small"
          label="Cancel"
          onClick={() => { showResubmitShipmentModal(null); }}
        />
        <Button
          variant="primary"
          size="small"
          label="Resubmit"
          destructive
          onClick={() => {
            dispatch(resubmitShipment({ orderId, shipmentId }));
            showResubmitShipmentModal(null);
          }}
        />
      </div>
    </Modal>
  );
};

export default ResubmitShipmentModal;
