import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { convertCountryCodeToName, emailRegex } from 'app/utils'
import { PencilSquare } from 'react-bootstrap-icons';
import { Input, Dropdown, ButtonIcon, Card, Button } from 'app/components'
import { CountryList } from 'app/constants';
import { createMerchant, updateMerchant } from 'app/store/actions/merchant';
import DataPoint from 'app/components/DataPoint';
import { usePermission } from 'app/permissions';
import './index.scss';
import PropTypes from 'prop-types';

const MerchantCard = props => {
  const { merchantDetails } = props;
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get user permissions
  const canUpdateMerchants = usePermission('merchant', 'create');

  useEffect(() => {
    if (!merchantDetails) {
      setEditMode(true);
    }
  }, []);

  const onMerchantCreated = (merchantId) => {
    navigate(`/admin/merchants/${merchantId}`);
  }

  const onMerchantUpdated = () => {
    setEditMode(false);
  }

  return (
    <Card className="merchant-card">
      <Card.Header>
        Merchant Details
        {!editMode && canUpdateMerchants && (
          <ButtonIcon
            icon={<PencilSquare />}
            onClick={() => setEditMode(true)}
          />
        )}
      </Card.Header>
      <Card.Body>
        {!editMode && merchantDetails && (
          <>
            <div className="merchant-information">
              <DataPoint title="Legal Name" data={merchantDetails.legalBusinessName} />
              <DataPoint title="Name" data={merchantDetails.name} />
              <DataPoint title="Email" data={merchantDetails.email} />
            </div>
            <div className="address-information view-mode">Address Information</div>
            <div className="merchant-information">
              <DataPoint title="Address Line 1" data={merchantDetails.address1} />
              {merchantDetails.address2 && <DataPoint title="Address Line 2" data={merchantDetails.address2} />}
              <DataPoint title="City" data={merchantDetails.city} />
              <DataPoint title="State / Province" data={merchantDetails.state} />
              <DataPoint title="Zip / Postal Code" data={merchantDetails.zipCode} />
              <DataPoint title="Country" data={convertCountryCodeToName(merchantDetails.country)} />
            </div>
          </>
        )}
        {editMode && (
          <Formik
            enableReinitialize
            initialValues={{
              merchantId: merchantDetails?.id || '',
              merchantLegalName: merchantDetails?.legalBusinessName || '',
              email: merchantDetails?.email || '',
              merchantName: merchantDetails?.name || '',
              address1: merchantDetails?.address1 || '',
              address2: merchantDetails?.address2 || '',
              city: merchantDetails?.city || '',
              state: merchantDetails?.state || '',
              zipPostalCode: merchantDetails?.zipCode || '',
              countryCode: merchantDetails?.country || 'US',
            }}
            validationSchema={() =>
              object().shape({
                merchantLegalName: string().required('Enter a valid Merchant Legal Name'), // required
                merchantName: string().required('Enter a valid Merchant Name'), // required
                email: string()
                  .matches(
                    emailRegex,
                    'Enter a valid Email'
                  )
                  .required('Enter a valid Email'),
                address1: string()
                  .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 1') // address
                  .matches(/[\x20-\x7F]+/, 'Enter a valid Address 1') // onlyEnglish
                  .max(35, 'Please limit to 35 characters')
                  .required('Enter a valid Address 1'), // required
                address2: string().when(['address1', 'countryCode'], (address1, countryCode) => {
                  if (countryCode !== 'US') {
                    if (address1) {
                      return string()
                        .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                        .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                        .max(
                          35 - address1.length,
                          'Please limit to 35 characters for Address 1 + Address 2'
                        );
                    } else {
                      return string()
                        .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                        .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                        .max(35, 'Please limit to 35 characters for Address 1 + Address 2');
                    }
                  } else {
                    return string()
                      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                      .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                      .max(35, 'Please limit to 35 characters');
                  }
                }),
                city: string()
                  .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid City') // address
                  .matches(/[\x20-\x7F]+/, 'Enter a valid City') // onlyEnglish
                  .required('Enter a valid City'), // required
                state: string()
                  .when('countryCode', {
                    is: (countryCode) => ['CA', 'US'].includes(countryCode),
                    then: () => string().required('Enter a valid State/Province'),
                    otherwise: () => string()
                  })
                  .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid State/Province')
                  .matches(/[\x20-\x7F]+/, 'Enter a valid State/Province'),
                zipPostalCode: string().when('countryCode', {
                  is: countryCode => countryCode === 'US',
                  then: () => string()
                    .matches(/^[a-zA-Z\d-]*$/, 'Enter a valid zip code') // zipcode
                    .required('Enter a valid zip code'),
                  otherwise: () => string().when('countryCode', {
                    is: countryCode => countryCode === 'CA',
                    then: () => string()
                      .matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                      .required('Enter a valid postal code'),
                    otherwise: () => string().matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                  })
                }),
                countryCode: string().required('Enter a Country'), // required
              })
            }
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const data = {
                "legalBusinessName": values.merchantLegalName,
                "name": values.merchantName,
                "email": values.email,
                "address1": values.address1,
                "address2": values.address2,
                "city": values.city,
                "state": values.state,
                "zipCode": values.zipPostalCode,
                "country": values.countryCode,
              };

              if (merchantDetails) {
                // update existing merchant
                data.id = merchantDetails.id;
                data.isParent = merchantDetails.isParent;
                data.parentId = merchantDetails.parent?.id;

                dispatch(updateMerchant({ data, cb: onMerchantUpdated }));
              } else {
                // create new merchant
                dispatch(createMerchant({ data, cb: onMerchantCreated }));
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              submitCount,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={`merchant-information ${!merchantDetails ? 'new-merchant' : ''}`}>
                  <Input
                    label="Legal Name"
                    name="merchantLegalName"
                    value={values.merchantLegalName}
                    onChange={handleChange}
                    placeholder="Legal Name"
                    errorMessage={submitCount > 0 && errors.merchantLegalName}
                  />
                  <Input
                    label="Merchant Name"
                    name="merchantName"
                    value={values.merchantName}
                    onChange={handleChange}
                    placeholder="Merchant Name"
                    errorMessage={submitCount > 0 && errors.merchantName}
                  />
                  <Input
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Email"
                    errorMessage={submitCount > 0 && errors.email}
                  />
                </div>
                <div className="address-information">Address Information</div>
                <div className={`merchant-information ${!merchantDetails ? 'new-merchant' : ''}`}>
                  <Input
                    label="Address Line 1"
                    name="address1"
                    value={values.address1}
                    onChange={handleChange}
                    placeholder="Street Address"
                    errorMessage={submitCount > 0 && errors.address1}
                  />
                  <Input
                    label="Address Line 2"
                    name="address2"
                    value={values.address2}
                    onChange={handleChange}
                    placeholder="Apt, Suite, unit, building, floor, etc"
                    errorMessage={submitCount > 0 && errors.address2}
                  />
                  <Input
                    label="City"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    placeholder="City"
                    errorMessage={submitCount > 0 && errors.city}
                  />
                  <Input
                    label="State / Province"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    placeholder="State / Province"
                    errorMessage={submitCount > 0 && errors.state}
                  />
                   <Input
                    label="Zip / Postal Code"
                    name="zipPostalCode"
                    value={values.zipPostalCode}
                    onChange={handleChange}
                    placeholder="Zip / Postal Code"
                    errorMessage={submitCount > 0 && errors.zipPostalCode}
                  />
                  <Dropdown
                    label="Country"
                    name="countryCode"
                    value={values.countryCode}
                    onChange={handleChange}
                    options={CountryList.map(country => (
                      { value: country.CountryCode, label: `${country.CountryCode} - ${country.Name}` }
                    ))}
                    errorMessage={submitCount > 0 && errors.countryCode}
                  />
                </div>
                <div className="action-buttons">
                  <Button
                    variant="secondary"
                    size="small"
                    label="Cancel"
                    disabled={isSubmitting}
                    onClick={() => !merchantDetails ? navigate('/admin/merchants/') : setEditMode(false)}
                  />
                  <Button
                    variant="primary"
                    size="small"
                    label={!merchantDetails ? 'Create Merchant' : 'Update'}
                    disabled={isSubmitting}
                    onClick={() => (isSubmitting ? null : handleSubmit())}
                  />
                </div>
              </form>
            )}
          </Formik>
        )}
      </Card.Body>
    </Card>
  )
}

MerchantCard.propTypes = {
  merchantDetails: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    enabled: PropTypes.bool,
    id: PropTypes.string,
    isParent: PropTypes.bool,
    legalBusinessName: PropTypes.string,
    name: PropTypes.string,
    parent: PropTypes.shape({
      id: PropTypes.string
    }),
    state: PropTypes.string,
    zipCode: PropTypes.string,
  })
};

export default MerchantCard;