import { createAction } from 'redux-actions';

const prefix = "SHIPMENT";

export const getShipmentsByOrderId = createAction(`${prefix}/GET_SHIPMENTS_BY_ORDER_ID`);
export const getShipmentsByOrderIdSuccess = createAction(`${prefix}/GET_SHIPMENTS_BY_ORDER_ID_SUCCESS`);
export const getShipmentsByOrderIdFailure = createAction(`${prefix}/GET_SHIPMENTS_BY_ORDER_ID_FAILURE`);

export const updateShipmentById = createAction(`${prefix}/UPDATE_SHIPMENT_BY_ID`);
export const updateShipmentByIdSuccess = createAction(`${prefix}/UPDATE_SHIPMENT_BY_ID_SUCCESS`);
export const updateShipmentByIdFailure = createAction(`${prefix}/UPDATE_SHIPMENT_BY_ID_FAILURE`);

export const rerouteShipment = createAction(`${prefix}/REROUTE_SHIPMENT`);
export const rerouteShipmentSuccess = createAction(`${prefix}/REROUTE_SHIPMENT_SUCCESS`);
export const rerouteShipmentFailure = createAction(`${prefix}/REROUTE_SHIPMENT_FAILURE`);

export const cancelShipment = createAction(`${prefix}/CANCEL_SHIPMENT`);
export const cancelShipmentSuccess = createAction(`${prefix}/CANCEL_SHIPMENT_SUCCESS`);
export const cancelShipmentFailure = createAction(`${prefix}/CANCEL_SHIPMENT_FAILURE`);

export const rejectShipment = createAction(`${prefix}/REJECT_SHIPMENT`);
export const rejectShipmentSuccess = createAction(`${prefix}/REJECT_SHIPMENT_SUCCESS`);
export const rejectShipmentFailure = createAction(`${prefix}/REJECT_SHIPMENT_FAILURE`);

export const resubmitShipment = createAction(`${prefix}/RESUBMIT_SHIPMENT`);
export const resubmitShipmentSuccess = createAction(`${prefix}/RESUBMIT_SHIPMENT_SUCCESS`);
export const resubmitShipmentFailure = createAction(`${prefix}/RESUBMIT_SHIPMENT_FAILURE`);

export const assignItemsToShipment = createAction(`${prefix}/ASSIGN_ITEMS_TO_SHIPMENT`);
export const assignItemsToShipmentSuccess = createAction(`${prefix}/ASSIGN_ITEMS_TO_SHIPMENT_SUCCESS`);
export const assignItemsToShipmentFailure = createAction(`${prefix}/ASSIGN_ITEMS_TO_SHIPMENT_FAILURE`);

export const updatePackageById = createAction(`${prefix}/UPDATE_PACKAGE_BY_ID`);
export const updatePackageByIdSuccess = createAction(`${prefix}/UPDATE_PACKAGE_BY_ID_SUCCESS`);
export const updatePackageByIdFailure = createAction(`${prefix}/UPDATE_PACKAGE_BY_ID_FAILURE`);

export const unassignItems = createAction(`${prefix}/UNASSIGN_ITEM`);
export const unassignItemsSuccess = createAction(`${prefix}/UNASSIGN_ITEM_SUCCESS`);
export const unassignItemsFailure = createAction(`${prefix}/UNASSIGN_ITEM_FAILURE`);

export const getAuditInfo = createAction(`${prefix}/GET_AUDIT_INFO`);
export const getAuditInfoSuccess = createAction(`${prefix}/GET_AUDIT_INFO_SUCCESS`);
export const getAuditInfoFailure = createAction(`${prefix}/GET_AUDIT_INFO_FAILURE`);

export const reset = createAction(`${prefix}/RESET`);