import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, LoadingAnimation, ButtonIcon, TabbedMenu } from 'app/components';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux';
import StrategyOverview from './StrategyOverview';
import DataSets from './DataSets';
import { getStrategyInfo } from 'app/store/actions/routing';
import { strategyInfoSelector, strategyInfoLoadingSelector } from "app/store/selectors/routing";
import './index.scss';

const strategyOverviewTab = {
  path: 'strategy-overview',
  label: 'Strategy Overview',
  content: StrategyOverview
}

const dataSetsTab = {
  path: 'data-sets',
  label: 'Data Sets',
  content: DataSets
}

const StrategyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { merchantId, strategyId } = useParams();

  useEffect(() => {
    dispatch(getStrategyInfo({ strategyId }));
  }, [strategyId, dispatch]);

  const strategyInfo = useSelector(strategyInfoSelector);
  const strategyInfoLoading = useSelector(strategyInfoLoadingSelector);

  const tabs = () => {
    return [strategyOverviewTab, dataSetsTab];
  }

  return (
    <>
      {strategyInfoLoading && <LoadingAnimation />}
      <div className="strategy-details">
        <Card>
          <div className="merchant-name-container">
            <div className="merchant-name">
              <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/routing')} />
              {strategyInfo?.routingStrategyName || ''}
            </div>
          </div>
        </Card>
        <TabbedMenu tabs={tabs()} showTabs={Boolean(strategyInfo)} strategyId={strategyId} merchantId={merchantId} />
      </div>
    </>
  )
}

export default StrategyDetails;