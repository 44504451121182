import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getShipmentsByOrderId,
  getShipmentsByOrderIdSuccess,
  getShipmentsByOrderIdFailure,
  updateShipmentById,
  updateShipmentByIdSuccess,
  updateShipmentByIdFailure,
  rerouteShipment,
  rerouteShipmentSuccess,
  rerouteShipmentFailure,
  cancelShipment,
  cancelShipmentSuccess,
  cancelShipmentFailure,
  rejectShipment,
  rejectShipmentSuccess,
  rejectShipmentFailure,
  resubmitShipment,
  resubmitShipmentSuccess,
  resubmitShipmentFailure,
  assignItemsToShipment,
  assignItemsToShipmentSuccess,
  assignItemsToShipmentFailure,
  updatePackageById,
  updatePackageByIdSuccess,
  updatePackageByIdFailure,
  unassignItems,
  unassignItemsSuccess,
  unassignItemsFailure,
  getAuditInfo,
  getAuditInfoSuccess,
  getAuditInfoFailure,
} from "app/store/actions/shipment"
import ShipmentServices from 'app/services/shipmentServices';
import HistoryServices from 'app/services/historyServices';
import OrderServices from 'app/services/orderServices';
import { getLatestHistorySuccess } from 'app/store/actions/history';
import { getOrderDetailsSuccess } from 'app/store/actions/order';

function* fetchShipmentsByOrderId(action) {
  const orderId = action.payload;
  try {
    const resp = yield call([ShipmentServices, ShipmentServices.getShipmentsByOrderId], orderId);
    yield put(getShipmentsByOrderIdSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getShipmentsByOrderIdFailure(error));
  }
}

function* doUpdateShipmentById(action) {
  const { orderId, shipmentId, data, cb } = action.payload;
  try {
    yield call([ShipmentServices, ShipmentServices.updateShipmentById], shipmentId, data);
    try {
      // get the latest order details
      const orderDetails = yield call([OrderServices, OrderServices.getOrderDetails], orderId);
      yield put(getOrderDetailsSuccess(orderDetails));
      // get the latest shipment details
      const shipmentDetails = yield call([ShipmentServices, ShipmentServices.getShipmentsByOrderId], orderId);
      yield put(getShipmentsByOrderIdSuccess(shipmentDetails));
      // get the latest history details
      const historyDetails = yield call([HistoryServices, HistoryServices.getHistory], 'Order', orderId, null, 1, 5, null);
      yield put(getLatestHistorySuccess(historyDetails));
    } catch (error) {
      // dont bother throwing an error, not a big deal if this fails
    } finally {
      yield put(updateShipmentByIdSuccess());
      toast.success("Update successful", {
        theme: 'colored',
      });
      if(cb) cb();
    }
  } catch (error) {
    console.error('error', error);
    yield put(updateShipmentByIdFailure(error));
    toast.error("Failed to update shipment", {
      theme: 'colored',
    })
  }
}

function* doRerouteShipment(action) {
  const { orderId, data, cb } = action.payload;
  try {
    yield call([ShipmentServices, ShipmentServices.rerouteShipment], data);
    yield put(rerouteShipmentSuccess());
    toast.success("Your routing request was received", {
      theme: 'colored',
    });
    if(cb) cb();
    try {
      const resp = yield call([HistoryServices, HistoryServices.getHistory], 'Order', orderId, null, 1, 5, null);
      yield put(getLatestHistorySuccess(resp));
    } catch (error) {
      // dont bother throwing an error, not a big deal if this fails
    }
  } catch (error) {
    console.error('error', error);
    yield put(rerouteShipmentFailure(error));
    toast.error("Failed to reroute shipment", {
      theme: 'colored',
    })
  }
}

function* doCancelShipment(action) {
  const { orderId, shipmentId, data, cb } = action.payload;

  let customErrorMessage = null;

  try {
    const resp = yield call([ShipmentServices, ShipmentServices.cancelShipment], shipmentId, data);
    // sometimes a failed cancel shipment will still return a 200 status code.  Check for errors
    if (resp.errors?.length > 0) {
      customErrorMessage = resp.errors[0].errorMessage;
      throw new Error();
    }

    try {
      // get the latest order details
      const orderDetails = yield call([OrderServices, OrderServices.getOrderDetails], orderId);
      yield put(getOrderDetailsSuccess(orderDetails));
      // get the latest shipment details
      const shipmentDetails = yield call([ShipmentServices, ShipmentServices.getShipmentsByOrderId], orderId);
      yield put(getShipmentsByOrderIdSuccess(shipmentDetails));
      // get the latest history details
      const historyDetails = yield call([HistoryServices, HistoryServices.getHistory], 'Order', orderId, null, 1, 5, null);
      yield put(getLatestHistorySuccess(historyDetails));
    } catch (error) {
      // dont bother throwing an error, not a big deal if this fails
    } finally {
      yield put(cancelShipmentSuccess({shipmentId}));
      toast.success("Cancellation successful", {
        theme: 'colored',
      });
      if(cb) cb();
    }
  } catch (error) {
    console.error('error', error);
    yield put(cancelShipmentFailure(error));
    toast.error(customErrorMessage || "Cancellation Failed", {
      theme: 'colored',
    })
  }
}

function* doRejectShipment(action) {
  const { data, orderId, cb } = action.payload;
  try {
    yield call([ShipmentServices, ShipmentServices.rejectShipment], data);
    try {
      // get the latest order details
      const orderDetails = yield call([OrderServices, OrderServices.getOrderDetails], orderId);
      yield put(getOrderDetailsSuccess(orderDetails));
      // get the latest shipment details
      const shipmentDetails = yield call([ShipmentServices, ShipmentServices.getShipmentsByOrderId], orderId);
      yield put(getShipmentsByOrderIdSuccess(shipmentDetails));
      // get the latest history details
      const historyDetails = yield call([HistoryServices, HistoryServices.getHistory], 'Order', orderId, null, 1, 5, null);
      yield put(getLatestHistorySuccess(historyDetails));
    } catch (error) {
      // dont bother throwing an error, not a big deal if this fails
    } finally {
      yield put(rejectShipmentSuccess());
      toast.success("Shipment successfully rejected", {
        theme: 'colored',
      });
      if(cb) cb();
    }
  } catch (error) {
    console.error('error', error);
    yield put(rejectShipmentFailure(error));
    toast.error("Failed to reject shipment", {
      theme: 'colored',
    })
  }
}

function* doResubmitShipment(action) {
  const { orderId, shipmentId, data, cb } = action.payload;
  try {
    yield call([ShipmentServices, ShipmentServices.resubmitShipment], shipmentId, data);
    try {
      // get the latest order details
      const orderDetails = yield call([OrderServices, OrderServices.getOrderDetails], orderId);
      yield put(getOrderDetailsSuccess(orderDetails));
      // get the latest shipment details
      const shipmentDetails = yield call([ShipmentServices, ShipmentServices.getShipmentsByOrderId], orderId);
      yield put(getShipmentsByOrderIdSuccess(shipmentDetails));
      // get the latest history details
      const historyDetails = yield call([HistoryServices, HistoryServices.getHistory], 'Order', orderId, null, 1, 5, null);
      yield put(getLatestHistorySuccess(historyDetails));
    } catch (error) {
      // dont bother throwing an error, not a big deal if this fails
    } finally {
      yield put(resubmitShipmentSuccess());
      toast.success("Shipment successfully resubmitted", {
        theme: 'colored',
      });
      if(cb) cb();
    }
  } catch (error) {
    console.error('error', error);
    yield put(resubmitShipmentFailure(error));
    toast.error("Failed to resubmit shipment", {
      theme: 'colored',
    })
  }
}

function* doAssignItemsToShipment(action) {
  const { data, orderId, cb } = action.payload;
  try {
    yield call([ShipmentServices, ShipmentServices.assignItemsToShipment], data);
    try {
      // get the latest order details
      const orderDetails = yield call([OrderServices, OrderServices.getOrderDetails], orderId);
      yield put(getOrderDetailsSuccess(orderDetails));
      // get the latest shipment details
      const shipmentDetails = yield call([ShipmentServices, ShipmentServices.getShipmentsByOrderId], orderId);
      yield put(getShipmentsByOrderIdSuccess(shipmentDetails));
      // get the latest history details
      const historyDetails = yield call([HistoryServices, HistoryServices.getHistory], 'Order', orderId, null, 1, 5, null);
      yield put(getLatestHistorySuccess(historyDetails));
    } catch (error) {
      // dont bother throwing an error, not a big deal if this fails
    } finally {
      yield put(assignItemsToShipmentSuccess());
      toast.success("Shipment successfully created", {
        theme: 'colored',
      });
      if(cb) cb();
    }
  } catch (error) {
    console.error('error', error);
    yield put(assignItemsToShipmentFailure(error));
    toast.error("Failed to create shipment", {
      theme: 'colored',
    })
  }
}

function* doUpdatePackageById(action) {
  const { orderId, shipmentId, packages, showToast = false } = action.payload.data;

  try {
    // loop through each package and call the updatePackageById API with the appropriate data format
    for (const pkg of packages) {
      const { pkgId, carrierTrackingNumber, carrierLink } = pkg;
      const data = {
        ...(carrierTrackingNumber && { carrierTrackingNumber }),
        ...(carrierLink && { carrierLink }),
      };

      // call the API to update the package
      yield call([ShipmentServices, ShipmentServices.updatePackageById], shipmentId, pkgId, data);
      // get the latest shipment details
      const shipmentDetails = yield call([ShipmentServices, ShipmentServices.getShipmentsByOrderId], orderId);
      yield put(getShipmentsByOrderIdSuccess(shipmentDetails));
    }

    yield put(updatePackageByIdSuccess());
    if(showToast) {
      toast.success(`Package${packages.length > 1 ? 's' : ''} successfully updated`, {
        theme: 'colored',
      });
    }
  } catch (error) {
    console.error('error', error);

    yield put(updatePackageByIdFailure(error));
    toast.error("Failed to update package(s)", {
      theme: 'colored',
    });
  }
}

function* doUnassignItems(action) {
  const { orderId, shipmentId, itemIds, cb } = action.payload;
  try {
    // the itemIds are the orderItemIDs.  Loop over them and unassign each one
    for (let i = 0; i < itemIds.length; i++) {
      const data = {
        orderItemId: itemIds[i],
      }
      yield call([ShipmentServices, ShipmentServices.unassignItem], shipmentId, data);
    }
    try {
      // get the latest order details
      const orderDetails = yield call([OrderServices, OrderServices.getOrderDetails], orderId);
      yield put(getOrderDetailsSuccess(orderDetails));
      // get the latest shipment details
      const shipmentDetails = yield call([ShipmentServices, ShipmentServices.getShipmentsByOrderId], orderId);
      yield put(getShipmentsByOrderIdSuccess(shipmentDetails));
      // get the latest history details
      const historyDetails = yield call([HistoryServices, HistoryServices.getHistory], 'Order', orderId, null, 1, 5, null);
      yield put(getLatestHistorySuccess(historyDetails));
    } catch (error) {
      // dont bother throwing an error, not a big deal if this fails
    } finally {
      yield put(unassignItemsSuccess());
      toast.success(`Item${itemIds.length > 1 ? 's' : ''} successfully unassigned`, {
        theme: 'colored',
      });
      if(cb) cb();
    }
  }
  catch (error) {
    console.error('error', error);
    yield put(unassignItemsFailure(error));
    toast.error("Failed to unassign items", {
      theme: 'colored',
    })
  }
}

function* fetchAuditInfo(action) {
  const { auditUrl, cb } = action.payload;
  try {
    const resp = yield call([ShipmentServices, ShipmentServices.getAuditInfo], auditUrl);
    yield put(getAuditInfoSuccess(resp));
    if(cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getAuditInfoFailure(error));
    toast.error("Failed to get audit info", {
      theme: 'colored',
    })
  }
}

function* watchData() {
  yield takeEvery(getShipmentsByOrderId.toString(), fetchShipmentsByOrderId);
  yield takeEvery(updateShipmentById.toString(), doUpdateShipmentById);
  yield takeEvery(rerouteShipment.toString(), doRerouteShipment);
  yield takeEvery(cancelShipment.toString(), doCancelShipment);
  yield takeEvery(rejectShipment.toString(), doRejectShipment);
  yield takeEvery(resubmitShipment.toString(), doResubmitShipment);
  yield takeEvery(assignItemsToShipment.toString(), doAssignItemsToShipment);
  yield takeEvery(updatePackageById.toString(), doUpdatePackageById);
  yield takeEvery(unassignItems.toString(), doUnassignItems);
  yield takeEvery(getAuditInfo.toString(), fetchAuditInfo);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}