import { createSelector } from 'reselect';

const routingSelector = state => state.routing;

export const merchantStrategiesSelector = createSelector([routingSelector], routingStore => routingStore.merchantStrategies.data);
export const merchantStrategiesLoadingSelector = createSelector([routingSelector], routingStore => routingStore.merchantStrategies.loading);
export const merchantStrageiesErrorSelector = createSelector([routingSelector], routingStore => routingStore.merchantStrategies.error);

export const createStrategyLoadingSelector = createSelector([routingSelector], routingStore => routingStore.createRoutingStrategy.loading);
export const updateStrategyLoadingSelector = createSelector([routingSelector], routingStore => routingStore.updateRoutingStrategy.loading);
export const deleteStrategyLoadingSelector = createSelector([routingSelector], routingStore => routingStore.deleteRoutingStrategy.loading);

export const activateStrategyLoadingSelector = createSelector([routingSelector], routingStore => routingStore.activateRoutingStrategy.loading);
export const activateStrategyErrorSelector = createSelector([routingSelector], routingStore => routingStore.activateRoutingStrategy.error);

export const strategyInfoSelector = createSelector([routingSelector], routingStore => routingStore.strategyInfo.data);
export const strategyInfoLoadingSelector = createSelector([routingSelector], routingStore => routingStore.strategyInfo.loading);
export const strategyInfoErrorSelector = createSelector([routingSelector], routingStore => routingStore.strategyInfo.error);

export const getDataSetsSelector = createSelector([routingSelector], routingStore => routingStore.strategyDataSets.data?.dataSets);
export const getDataSetsLoadingSelector = createSelector([routingSelector], routingStore => routingStore.strategyDataSets.loading);
export const getDataSetsErrorSelector = createSelector([routingSelector], routingStore => routingStore.strategyDataSets.error);
