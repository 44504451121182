import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect as MultiSelectComponent } from "react-multi-select-component";
import { Checkbox } from 'app/components';
import './index.scss';

const MultiSelect = ({
  options,
  value,
  onChange,
  label,
  maxDisplayedValueLength = 3, 
  className = '',               
  readonly = false,             
  customValueRenderer = null,   
  errorMessage = '',            
}) => {

  const valueRenderer = (selected) => {
    if (selected.length> 1 && selected.length === options.length) {
      return `You have selected all ${selected.length} items`;
    }
    return selected.length > maxDisplayedValueLength
      ? `You have selected ${selected.length} item${selected.length > 1 ? 's' : ''}`
      : selected.map((item) => item.label).join(", ");
  };

  const LineItem = ({ label, checked, onChange }) => {
    return (
      <div className={`line-item ${readonly ? 'readonly' : ''}`} onClick={() => !readonly && onChange(!checked)}>
        <Checkbox size="small" checked={checked} disabled={readonly} />
        <div>{label}</div>
      </div>
    );
  };

  const showError = errorMessage && errorMessage.trim().length > 0;

  return (
    <div className={`gooten-multi-select with-floating-label ${showError ? 'has-error' : ''} ${className}`}>
      {label && <label>{label}</label>}
      <MultiSelectComponent
        options={options}
        value={value}
        onChange={(e) => readonly ? null : onChange(e)}
        overrideStrings={{ "selectSomeItems": label }}
        valueRenderer={customValueRenderer || valueRenderer}
        className={`${className} ${showError ? 'has-error' : ''}`}
        ClearSelectedIcon={readonly ? null : undefined}
        hasSelectAll={readonly ? false : true}
        ItemRenderer={({ checked, option, onClick }) => (
          <LineItem
            value={option.value}
            label={option.label}
            checked={checked}
            onChange={() => onClick(option.value)}
            readonly={readonly}
          />
        )}
      />
      <div className="error">{showError && errorMessage}</div>
    </div>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  maxDisplayedValueLength: PropTypes.number,
  className: PropTypes.string,
  readonly: PropTypes.bool,
  customValueRenderer: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default MultiSelect;
